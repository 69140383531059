define("@ember-data/private-build-infra/deprecations", ["exports", "@ember-data/private-build-infra/current-deprecations"], function (_exports, _currentDeprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEPRECATE_STRING_ARG_SCHEMAS = _exports.DEPRECATE_STORE_FIND = _exports.DEPRECATE_SNAPSHOT_MODEL_CLASS_ACCESS = _exports.DEPRECATE_SAVE_PROMISE_ACCESS = _exports.DEPRECATE_RSVP_PROMISE = _exports.DEPRECATE_RECORD_WAS_INVALID = _exports.DEPRECATE_JSON_API_FALLBACK = _exports.DEPRECATE_HAS_RECORD = _exports.DEPRECATE_CATCH_ALL = _exports.DEPRECATE_3_12 = void 0;
  function deprecationState(deprecationName) {
    // if we hit this at runtime and the deprecation exists it is always activated
    return deprecationName in _currentDeprecations.default;
  }

  // deprecations
  const DEPRECATE_CATCH_ALL = _exports.DEPRECATE_CATCH_ALL = deprecationState('DEPRECATE_CATCH_ALL');
  const DEPRECATE_3_12 = _exports.DEPRECATE_3_12 = deprecationState('DEPRECATE_3_12');
  const DEPRECATE_SAVE_PROMISE_ACCESS = _exports.DEPRECATE_SAVE_PROMISE_ACCESS = deprecationState('DEPRECATE_SAVE_PROMISE_ACCESS');
  const DEPRECATE_RSVP_PROMISE = _exports.DEPRECATE_RSVP_PROMISE = deprecationState('DEPRECATE_RSVP_PROMISE');
  const DEPRECATE_SNAPSHOT_MODEL_CLASS_ACCESS = _exports.DEPRECATE_SNAPSHOT_MODEL_CLASS_ACCESS = deprecationState('DEPRECATE_SNAPSHOT_MODEL_CLASS_ACCESS');
  const DEPRECATE_STORE_FIND = _exports.DEPRECATE_STORE_FIND = deprecationState('DEPRECATE_STORE_FIND');
  const DEPRECATE_HAS_RECORD = _exports.DEPRECATE_HAS_RECORD = deprecationState('DEPRECATE_HAS_RECORD');
  const DEPRECATE_RECORD_WAS_INVALID = _exports.DEPRECATE_RECORD_WAS_INVALID = deprecationState('DEPRECATE_RECORD_WAS_INVALID');
  const DEPRECATE_STRING_ARG_SCHEMAS = _exports.DEPRECATE_STRING_ARG_SCHEMAS = deprecationState('DEPRECATE_STRING_ARG_SCHEMAS');
  const DEPRECATE_JSON_API_FALLBACK = _exports.DEPRECATE_JSON_API_FALLBACK = deprecationState('DEPRECATE_JSON_API_FALLBACK');
});