define("ember-tag-input/components/tag-input", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.tags as |tag index|~}}
    <li class="emberTagInput-tag {{tag.modifiers}}">
      {{yield tag}}
      {{#if this._isRemoveButtonVisible}}
        <a class="emberTagInput-remove" {{on 'click' (fn this.removeTag index)}}></a>
      {{/if}}
    </li>
  {{~/each~}}
  
  <li class="emberTagInput-new">
    <input
      disabled={{this.readOnly}}
      class={{concat 'emberTagInput-input js-ember-tag-input-new' (if this.readOnly ' is-disabled')}}
      maxlength={{this.maxlength}}
      placeholder={{this.placeholder}}
      aria-label={{this.ariaLabel}}
    />
  </li>
  
  */
  {
    "id": "DvDHiH6c",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"tags\"]]],null]],null],null,[[[10,\"li\"],[15,0,[29,[\"emberTagInput-tag \",[30,1,[\"modifiers\"]]]]],[12],[1,\"\\n    \"],[18,3,[[30,1]]],[1,\"\\n\"],[41,[30,0,[\"_isRemoveButtonVisible\"]],[[[1,\"      \"],[11,3],[24,0,\"emberTagInput-remove\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"removeTag\"]],[30,2]],null]],null],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13]],[1,2]],null],[10,\"li\"],[14,0,\"emberTagInput-new\"],[12],[1,\"\\n  \"],[10,\"input\"],[15,\"disabled\",[30,0,[\"readOnly\"]]],[15,0,[28,[37,6],[\"emberTagInput-input js-ember-tag-input-new\",[52,[30,0,[\"readOnly\"]],\" is-disabled\"]],null]],[15,\"maxlength\",[30,0,[\"maxlength\"]]],[15,\"placeholder\",[30,0,[\"placeholder\"]]],[15,\"aria-label\",[30,0,[\"ariaLabel\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"tag\",\"index\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"if\",\"on\",\"fn\",\"concat\"]]",
    "moduleName": "ember-tag-input/components/tag-input.hbs",
    "isStrictMode": false
  });
  const KEY_CODES = {
    BACKSPACE: 8,
    COMMA: 188,
    ENTER: 13,
    SPACE: 32
  };
  const TAG_CLASS = 'emberTagInput-tag';
  const REMOVE_CONFIRMATION_CLASS = 'emberTagInput-tag--remove';
  let TagInput = _exports.default = (_class = class TagInput extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "classNameBindings", [':emberTagInput', 'readOnly:emberTagInput--readOnly']);
      _defineProperty(this, "tagName", 'ul');
      _defineProperty(this, "tags", null);
      _defineProperty(this, "removeConfirmation", true);
      _defineProperty(this, "allowCommaInTags", false);
      _defineProperty(this, "allowDuplicates", false);
      _defineProperty(this, "allowSpacesInTags", false);
      _initializerDefineProperty(this, "showRemoveButtons", _descriptor, this);
      _initializerDefineProperty(this, "readOnly", _descriptor2, this);
      _defineProperty(this, "placeholder", '');
      _defineProperty(this, "ariaLabel", '');
      _defineProperty(this, "onKeyUp", false);
    }
    get _isRemoveButtonVisible() {
      return this.showRemoveButtons && !this.readOnly;
    }
    addNewTag(tag) {
      const tags = this.tags;
      const addTag = this.addTag;
      const allowDuplicates = this.allowDuplicates;
      if (!allowDuplicates && tags && tags.indexOf(tag) >= 0) {
        return false;
      }
      return addTag(tag) !== false;
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      this.initEvents();
    }
    dispatchKeyUp(value) {
      if (this.onKeyUp) {
        this.onKeyUp(value);
      }
    }
    _onContainerClick() {
      const newTagInput = this.element.querySelector('.js-ember-tag-input-new');
      const isReadOnly = this.readOnly;
      if (!isReadOnly) {
        newTagInput.focus();
      }
    }
    _onInputKeyDown(e) {
      if (!this.readOnly) {
        const {
          allowCommaInTags,
          allowSpacesInTags,
          tags
        } = this;
        const backspaceRegex = new RegExp(String.fromCharCode(KEY_CODES.BACKSPACE), 'g');
        const newTag = e.target.value.trim().replace(backspaceRegex, '');
        if (e.which === KEY_CODES.BACKSPACE) {
          if (newTag.length === 0 && tags.length > 0) {
            const removeTagAtIndex = this.removeTagAtIndex;
            if (this.removeConfirmation) {
              const tags = this.element.querySelectorAll('.' + TAG_CLASS);
              const lastTag = tags[tags.length - 1];
              if (lastTag && !lastTag.classList.contains(REMOVE_CONFIRMATION_CLASS)) {
                lastTag.classList.add(REMOVE_CONFIRMATION_CLASS);
                return;
              }
            }
            removeTagAtIndex(tags.length - 1);
          }
        } else {
          if (!allowCommaInTags && e.which === KEY_CODES.COMMA || !allowSpacesInTags && e.which === KEY_CODES.SPACE || e.which === KEY_CODES.ENTER) {
            if (newTag.length > 0) {
              if (this.addNewTag(newTag)) {
                e.target.value = '';
              }
            }
            e.preventDefault();
          }
          [].forEach.call(this.element.querySelectorAll('.' + TAG_CLASS), function (tagEl) {
            tagEl.classList.remove(REMOVE_CONFIRMATION_CLASS);
          });
        }
      }
    }
    _onInputBlur(e) {
      const newTag = e.target.value.trim();
      if (newTag.length > 0) {
        if (this.addNewTag(newTag)) {
          e.target.value = '';
          this.dispatchKeyUp('');
        }
      }
    }
    _onInputKeyUp(e) {
      this.dispatchKeyUp(e.target.value);
    }
    initEvents() {
      const container = this.element;
      const onContainerClick = this._onContainerClick.bind(this);
      const onInputKeyDown = this._onInputKeyDown.bind(this);
      const onInputBlur = this._onInputBlur.bind(this);
      const onInputKeyUp = this._onInputKeyUp.bind(this);
      container.addEventListener('click', onContainerClick);
      const newTagInput = this.element.querySelector('.js-ember-tag-input-new');
      newTagInput.addEventListener('keydown', onInputKeyDown);
      newTagInput.addEventListener('blur', onInputBlur);
      newTagInput.addEventListener('keyup', onInputKeyUp);
    }
    removeTag(index) {
      const removeTagAtIndex = this.removeTagAtIndex;
      removeTagAtIndex(index);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showRemoveButtons", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "readOnly", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "removeTag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeTag"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TagInput);
});